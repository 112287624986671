<template>
  <v-container id="crud" fluid tag="section">
    <v-card>
      <v-toolbar flat color="white">
        <v-toolbar-title>{{ title }}</v-toolbar-title>
      </v-toolbar>

      <v-data-table
        :headers="showHeaders"
        :items="filteredData"
        :search="search"
        sort-by="id"
        sort-desc
        class="elevation-1"
      >
        <template v-slot:[`header.cuit`]="{ header }">
          <filter-component
            v-bind:header="{ header }"
            v-bind:filters="filters"
          ></filter-component>
        </template>
        <template v-slot:[`header.razon_social`]="{ header }">
          <filter-component
            v-bind:header="{ header }"
            v-bind:filters="filters"
          ></filter-component>
        </template>
        <template v-slot:[`header.estado_id`]="{ header }">
          <filter-component
            v-bind:header="{ header }"
            v-bind:filters="filters"
          ></filter-component>
        </template>
        <template v-slot:[`item.datos_entidad`]="{ item }">
          {{ JSON.parse(item.datos_entidad).mail }}
        </template>
        <template v-slot:[`item.documento`]="{ item }">
          {{
            JSON.parse(item.datos_usuario).tipo_documento.name +
            ": " +
            JSON.parse(item.datos_usuario).documento
          }}
        </template>
        <template v-slot:[`item.datos_usuario`]="{ item }">
          {{
            JSON.parse(item.datos_usuario).apellido +
            ", " +
            JSON.parse(item.datos_usuario).nombre
          }}
        </template>
        <template v-slot:[`item.estado_id`]="{ item }">
          <div
            :class="$getCss(item.estado_id, 'estado_solicitud')"
            style="width: 100px"
            dark
          >
            {{ $getValue(item.estado_id, "estado_solicitud") }}
          </div>
        </template>

        <template v-slot:top>
          <v-toolbar flat color="white" class="pr-4">
            <v-row>
              <v-col sm="6" class="pt-20">
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  :label="'Busqueda de ' + title"
                  single-line
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col sm="2"></v-col>
              <v-col sm="3" class="">
                <v-autocomplete
                  v-model="selectedHeaders"
                  :items="headers"
                  label="Columnas Visibles"
                  multiple
                  return-object
                >
                  <template v-slot:selection="{ item, index }">
                    <v-chip v-if="index < 2">
                      <span>{{ item.text }}</span>
                    </v-chip>
                    <span v-if="index === 2" class="grey--text caption"
                      >(otras {{ selectedHeaders.length - 2 }}+)</span
                    >
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col cols="12" md="12" sm="12"> </v-col>
            </v-row>
          </v-toolbar>
          <v-dialog
            v-if="showDialog == true"
            v-model="dialog"
            max-width="70%"
            persistent
          >
            <v-card
              v-if="editedIndex != -1"
              style="max-height: 90vh; overflow-y: auto"
            >
              <v-form ref="form" v-model="valid" lazy-validation>
                <v-toolbar dark :color="$cv('principal')">
                  <v-btn icon dark @click="dialog = false">
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                  <v-toolbar-title>{{
                    editedIndex != -1
                      ? formTitle +
                        ": " +
                        editedItem.razon_social +
                        " (" +
                        editedItem.cuit +
                        ")"
                      : formTitle
                  }}</v-toolbar-title>
                </v-toolbar>

                <v-card-text>
                  <v-row justify="center" align="start">
                    <v-col cols="12" md="12" sm="12" lg="12">
                      <v-card
                        class="pa-0 pl-3 pr-3 elevation-0 mt-2"
                        style="background: #eee"
                      >
                        <v-row justify="center" align="start">
                          <v-col cols="12" md="9" sm="12" lg="9">
                            <v-toolbar dark color="primary" class="mt-4">
                              <v-toolbar-title
                                >Datos del Concesionario</v-toolbar-title
                              >
                              <v-spacer></v-spacer>
                            </v-toolbar>

                            <v-card-text
                              class="elevation-1"
                              style="background: #fff"
                            >
                              <v-row class="pb-3">
                                <v-col cols="12" md="9" sm="12" class="py-0">
                                  <v-toolbar dark color="primary" class="mt-4">
                                    <v-toolbar-title>Dirección</v-toolbar-title>
                                    <v-spacer></v-spacer>
                                  </v-toolbar>

                                  <v-card-text
                                    class="elevation-1 pb-2"
                                    style="background: #fff"
                                  >
                                    <v-row>
                                      <v-col>
                                        <v-row>
                                          <v-col cols="12" md="4" sm="12">
                                            <div
                                              class="text-h4 font-weight-black"
                                            >
                                              <span>Provincia</span>
                                            </div>
                                            <div
                                              class="text-h4 font-weight-black blue-grey--text"
                                            >
                                              {{
                                                JSON.parse(
                                                  editedItem.datos_entidad
                                                ).provincia.nombre
                                              }}
                                            </div>
                                          </v-col>
                                          <v-col cols="12" md="4" sm="12">
                                            <div
                                              class="text-h4 font-weight-black"
                                            >
                                              <span>Partido</span>
                                            </div>
                                            <div
                                              class="text-h4 font-weight-black blue-grey--text"
                                            >
                                              {{
                                                JSON.parse(
                                                  editedItem.datos_entidad
                                                ).partido
                                                  ? JSON.parse(
                                                      editedItem.datos_entidad
                                                    ).partido.nombre
                                                  : "No disponible"
                                              }}
                                            </div>
                                          </v-col>

                                          <v-col cols="12" md="4" sm="12">
                                            <div
                                              class="text-h4 font-weight-black"
                                            >
                                              <span>Localidad</span>
                                            </div>
                                            <div
                                              class="text-h4 font-weight-black blue-grey--text"
                                            >
                                              {{
                                                JSON.parse(
                                                  editedItem.datos_entidad
                                                ).ciudad.nombre
                                              }}
                                            </div>
                                          </v-col>
                                        </v-row>
                                        <v-row>
                                          <v-col cols="12" md="8" sm="12">
                                            <div
                                              class="text-h4 font-weight-black"
                                            >
                                              <span>Calle</span>
                                            </div>
                                            <div
                                              class="text-h4 font-weight-black blue-grey--text"
                                            >
                                              {{
                                                editedItem.datos_entidad
                                                  ? JSON.parse(
                                                      editedItem.datos_entidad
                                                    ).calle
                                                  : ""
                                              }}
                                            </div>
                                          </v-col>
                                          <v-col cols="12" md="4" sm="12">
                                            <div
                                              class="text-h4 font-weight-black"
                                            >
                                              <span>Número</span>
                                            </div>
                                            <div
                                              class="text-h4 font-weight-black blue-grey--text"
                                            >
                                              {{
                                                editedItem.datos_entidad
                                                  ? JSON.parse(
                                                      editedItem.datos_entidad
                                                    ).numero
                                                  : ""
                                              }}
                                            </div>
                                          </v-col>
                                        </v-row>
                                        <v-row>
                                          <v-col cols="12" md="4" sm="12">
                                            <div
                                              class="text-h4 font-weight-black"
                                            >
                                              <span>Piso</span>
                                            </div>
                                            <div
                                              class="text-h4 font-weight-black blue-grey--text"
                                            >
                                              {{
                                                editedItem.datos_entidad
                                                  ? JSON.parse(
                                                      editedItem.datos_entidad
                                                    ).piso
                                                  : ""
                                              }}
                                            </div>
                                          </v-col>
                                          <v-col cols="12" md="4" sm="12">
                                            <div
                                              class="text-h4 font-weight-black"
                                            >
                                              <span>Depto</span>
                                            </div>
                                            <div
                                              class="text-h4 font-weight-black blue-grey--text"
                                            >
                                              {{
                                                editedItem.datos_entidad
                                                  ? JSON.parse(
                                                      editedItem.datos_entidad
                                                    ).depto
                                                  : ""
                                              }}
                                            </div>
                                          </v-col>
                                          <v-col cols="12" md="4" sm="12">
                                            <div
                                              class="text-h4 font-weight-black"
                                            >
                                              <span>Código Postal</span>
                                            </div>
                                            <div
                                              class="text-h4 font-weight-black blue-grey--text"
                                            >
                                              {{
                                                JSON.parse(
                                                  editedItem.datos_entidad
                                                ).cp
                                              }}
                                            </div>
                                          </v-col>
                                        </v-row>
                                      </v-col>
                                    </v-row>
                                  </v-card-text>
                                </v-col>
                                <v-col cols="12" md="3" sm="12" class="py-0">
                                  <v-toolbar
                                    dark
                                    color="primary"
                                    class="mt-4 elevation-1"
                                  >
                                    <v-toolbar-title>Contacto</v-toolbar-title>
                                    <v-spacer></v-spacer>
                                  </v-toolbar>

                                  <v-card-text
                                    class="elevation-1 pb-2"
                                    style="background: #fff"
                                  >
                                    <v-row>
                                      <v-col>
                                        <v-row>
                                          <v-col cols="12" md="12" sm="12">
                                            <!--
                                                  <v-text-field
                                                    v-model="
                                                      editedItem.datos_entidad
                                                        .telefono
                                                    "
                                                    label="Telefono"
                                                    :rules="[
                                                      $rulesRequerido,
                                                      $rulesTelefono,
                                                    ]"
                                                  ></v-text-field>
                                                -->
                                            <div
                                              class="text-h4 font-weight-black"
                                            >
                                              <span>Teléfono</span>
                                            </div>
                                            <div
                                              class="text-h4 font-weight-black blue-grey--text"
                                            >
                                              {{
                                                JSON.parse(
                                                  editedItem.datos_entidad
                                                ).telefono
                                              }}
                                            </div>
                                          </v-col>
                                          <v-col cols="12" md="12" sm="12">
                                            <!--
                                                  <v-text-field
                                                    v-model="
                                                      editedItem.datos_entidad
                                                        .celular
                                                    "
                                                    label="Celular"
                                                    :rules="[
                                                      $rulesRequerido,
                                                      $rulesTelefono,
                                                    ]"
                                                  ></v-text-field>
                                                -->
                                            <div
                                              class="text-h4 font-weight-black"
                                            >
                                              <span>Celular</span>
                                            </div>
                                            <div
                                              class="text-h4 font-weight-black blue-grey--text"
                                            >
                                              {{
                                                JSON.parse(
                                                  editedItem.datos_entidad
                                                ).celular
                                              }}
                                            </div>
                                          </v-col>
                                          <v-col cols="12" md="12" sm="12">
                                            <!--
                                                  <v-text-field
                                                    v-model="
                                                      editedItem.datos_entidad
                                                        .mail
                                                    "
                                                    label="Email"
                                                    :rules="[
                                                      $rulesRequerido,
                                                      $rulesMail,
                                                    ]"
                                                  ></v-text-field>
                                                -->
                                            <div
                                              class="text-h4 font-weight-black"
                                            >
                                              <span>Email</span>
                                            </div>
                                            <div
                                              class="text-h4 font-weight-black blue-grey--text"
                                            >
                                              {{
                                                JSON.parse(
                                                  editedItem.datos_entidad
                                                ).mail
                                              }}
                                            </div>
                                          </v-col>
                                        </v-row>
                                      </v-col>
                                    </v-row>
                                  </v-card-text>
                                </v-col>
                              </v-row>
                            </v-card-text>
                          </v-col>
                          <v-col cols="12" md="3" sm="12">
                            <v-toolbar dark color="primary" class="mt-4">
                              <v-toolbar-title
                                >Datos de la Solicitud</v-toolbar-title
                              >
                              <v-spacer></v-spacer>
                            </v-toolbar>
                            <!--<v-card style="height: 300px">-->
                            <v-card-text
                              class="elevation-1 pb-2"
                              style="background: #fff"
                            >
                              <v-row>
                                <v-col>
                                  <v-row>
                                    <v-col cols="12" md="6" sm="12">
                                      <div class="text-h4 font-weight-black">
                                        <span>Numero</span>
                                      </div>
                                      <div
                                        class="text-h4 font-weight-black blue-grey--text"
                                      >
                                        {{ editedItem.id }}
                                      </div>
                                    </v-col>
                                    <v-col cols="12" md="6" sm="12">
                                      <div class="text-h4 font-weight-black">
                                        <span>Estado</span>
                                      </div>
                                      <div
                                        class="text-h4 font-weight-black blue-grey--text"
                                        style="font-size: 16px !important"
                                      >
                                        {{
                                          getDetalle(
                                            editedItem.estado_id,
                                            "estado"
                                          )
                                        }}
                                      </div>
                                    </v-col>
                                  </v-row>
                                  <v-row>
                                    <v-col cols="12" md="12" sm="12">
                                      <div class="text-h4 font-weight-black">
                                        <span>Fecha de Solicitud</span>
                                      </div>
                                      <div
                                        class="text-h4 font-weight-black blue-grey--text"
                                      >
                                        {{ editedItem.created_at }}
                                      </div>
                                    </v-col>
                                  </v-row>
                                  <v-row>
                                    <v-col cols="12" md="12" sm="12">
                                      <div class="text-h4 font-weight-black">
                                        <span>Ultima de Actualización</span>
                                      </div>
                                      <div
                                        class="text-h4 font-weight-black blue-grey--text"
                                      >
                                        {{ editedItem.updated_at }}
                                      </div>
                                    </v-col>
                                  </v-row>
                                  <v-row>
                                    <v-col cols="12" md="12" sm="12">
                                      <div class="text-h4 font-weight-black">
                                        <span>Comentarios</span>
                                      </div>
                                      <div
                                        class="text-h4 font-weight-black blue-grey--text"
                                      >
                                        {{ editedItem.comentario }}
                                      </div>
                                    </v-col>
                                  </v-row>
                                </v-col>
                              </v-row>
                            </v-card-text>
                            <!--</v-card>-->
                          </v-col>
                        </v-row>
                        <!--
                        <v-form
                          ref="solicitudUsuarioForm"
                          v-model="valid"
                          lazy-validation
                        >
                        -->
                        <v-row justify="center" align="center">
                          <v-col cols="12" md="12" sm="12" lg="12">
                            <v-toolbar dark color="primary" class="mt-4">
                              <v-toolbar-title
                                >Datos Del Usuario</v-toolbar-title
                              >
                              <v-spacer></v-spacer>
                            </v-toolbar>
                            <v-card-text
                              class="elevation-1"
                              style="background: #fff"
                            >
                              <v-row
                                :style="{
                                  borderRadius: '10px',
                                  margin: '4px',
                                }"
                              >
                                <v-col>
                                  <v-row>
                                    <v-col
                                      cols="12"
                                      md="3"
                                      sm="12"
                                      class="py-0"
                                    >
                                      <div class="text-h4 font-weight-black">
                                        <span>Apellido</span>
                                      </div>
                                      <div
                                        class="text-h4 font-weight-black blue-grey--text"
                                      >
                                        {{
                                          JSON.parse(editedItem.datos_usuario)
                                            .apellido
                                        }}
                                      </div>
                                    </v-col>
                                    <v-col
                                      cols="12"
                                      md="3"
                                      sm="12"
                                      class="py-0"
                                    >
                                      <div class="text-h4 font-weight-black">
                                        <span>Nombres</span>
                                      </div>
                                      <div
                                        class="text-h4 font-weight-black blue-grey--text"
                                      >
                                        {{
                                          JSON.parse(editedItem.datos_usuario)
                                            .nombre
                                        }}
                                      </div>
                                    </v-col>
                                    <v-col
                                      cols="12"
                                      md="3"
                                      sm="12"
                                      class="py-0"
                                    >
                                      <div class="text-h4 font-weight-black">
                                        <span>Tipo de Documento</span>
                                      </div>
                                      <div
                                        class="text-h4 font-weight-black blue-grey--text"
                                      >
                                        {{
                                          JSON.parse(editedItem.datos_usuario)
                                            .tipo_documento.name
                                        }}
                                      </div>
                                    </v-col>
                                    <v-col
                                      cols="12"
                                      md="3"
                                      sm="12"
                                      class="py-0"
                                    >
                                      <div class="text-h4 font-weight-black">
                                        <span>Documento</span>
                                      </div>
                                      <div
                                        class="text-h4 font-weight-black blue-grey--text"
                                      >
                                        {{
                                          JSON.parse(editedItem.datos_usuario)
                                            .documento
                                        }}
                                      </div>
                                    </v-col>
                                  </v-row>
                                  <v-row>
                                    <v-col cols="12" md="3" sm="12">
                                      <div class="text-h4 font-weight-black">
                                        <span>Usuario</span>
                                      </div>
                                      <div
                                        class="text-h4 font-weight-black blue-grey--text"
                                      >
                                        {{
                                          JSON.parse(editedItem.datos_usuario)
                                            .usuario
                                        }}
                                      </div>
                                    </v-col>
                                    <v-col cols="12" md="3" sm="12">
                                      <div class="text-h4 font-weight-black">
                                        <span>Teléfono</span>
                                      </div>
                                      <div
                                        class="text-h4 font-weight-black blue-grey--text"
                                      >
                                        {{
                                          JSON.parse(editedItem.datos_usuario)
                                            .telefono
                                        }}
                                      </div>
                                    </v-col>
                                    <v-col cols="12" md="3" sm="12">
                                      <div class="text-h4 font-weight-black">
                                        <span>Celular</span>
                                      </div>
                                      <div
                                        class="text-h4 font-weight-black blue-grey--text"
                                      >
                                        {{
                                          JSON.parse(editedItem.datos_usuario)
                                            .celular
                                        }}
                                      </div>
                                    </v-col>
                                    <v-col cols="12" md="3" sm="12">
                                      <div class="text-h4 font-weight-black">
                                        <span>Correo</span>
                                      </div>
                                      <div
                                        class="text-h4 font-weight-black blue-grey--text"
                                      >
                                        {{
                                          JSON.parse(editedItem.datos_usuario)
                                            .mail
                                        }}
                                      </div>
                                    </v-col>
                                  </v-row>
                                </v-col>
                              </v-row>
                            </v-card-text>
                          </v-col>
                        </v-row>

                        <br />
                        <!-- </v-form> -->
                      </v-card>
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col
                      cols="12"
                      md="12"
                      sm="12"
                      justify="end"
                      align="right"
                    >
                      <v-btn color="warning" @click="$close()">Cancelar</v-btn>
                      <v-btn
                        v-if="editedItem.estado_id == 1"
                        color="error"
                        @click="changeStatus(editedItem, 'Rechazar', 3)"
                        >Rechazar</v-btn
                      >

                      <v-btn
                        v-if="editedItem.estado_id == 1"
                        color="success"
                        @click="aprobarSolicitud(editedItem, 'Aprobar', 2)"
                        >Aprobar</v-btn
                      >
                      <!--
                      <v-btn
                        v-if="editedItem.estado_id == 1"
                        color="success"
                        @click="changeStatus(editedItem, 'Aprobar', 2)"
                        >Aprobar</v-btn
                      >-->
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-form>
            </v-card>
          </v-dialog>
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          <v-row class="">
            <v-col cols="6" md="6" sm="6" style="padding: 6px 0px 0px 0px">
              <v-menu
                bottom
                origin="center center"
                transition="scale-transition"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="transparent elevation-0"
                    dark
                    small
                    v-bind="attrs"
                    v-on="on"
                    min-width="30px"
                  >
                    <v-icon
                      large
                      color="blue-grey lighten-3"
                      style="font-size: 30px"
                    >
                      mdi-dialpad
                    </v-icon>
                  </v-btn>
                </template>

                <v-list>
                  <v-list-item
                    @click="
                      vista = true;
                      editItem(item.id);
                    "
                  >
                    <v-list-item-icon class="mr-0"
                      ><v-icon
                        small
                        class="mr-2"
                        :color="$cv('btnVista')"
                        title="Ver"
                      >
                        mdi-eye-outline
                      </v-icon> </v-list-item-icon
                    ><v-list-item-content>
                      <v-list-item-title>Ver</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <!--
                  <v-list-item
                    v-if="item.estado_id == 1"
                    @click="changeStatus(item, 'Aprobar', 2)"
                  >
                    <v-list-item-icon class="mr-0">
                      <v-icon
                        medium
                        class="mr-2"
                        color="green"
                        title="Aprobar Solicitud"
                      >
                        mdi-check-circle-outline
                      </v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>Aprobar</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  -->
                  <v-list-item
                    v-if="item.estado_id == 1"
                    @click="aprobarSolicitud(item, 'Aprobar', 2)"
                  >
                    <v-list-item-icon class="mr-0">
                      <v-icon
                        medium
                        class="mr-2"
                        color="green"
                        title="Aprobar Solicitud"
                      >
                        mdi-check-circle-outline
                      </v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>Aprobar</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item
                    v-if="item.estado_id == 1"
                    @click="changeStatus(item, 'Rechazar', 3)"
                  >
                    <v-list-item-icon class="mr-0">
                      <v-icon
                        medium
                        class="mr-2"
                        color="red"
                        title="Rechazar Solicitud"
                      >
                        mdi-close-circle
                      </v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>Rechazar</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <!--
                  <v-list-item
                    v-if="$can([titlePerms + '_update'])"
                    @click="
                      vista = false;
                      $editItem(item.id);
                    "
                  >
                    <v-list-item-icon class="mr-0"
                      ><v-icon
                        small
                        class="mr-2"
                        :color="$cv('btnEditar')"
                        title="Editar"
                      >
                        mdi-pencil
                      </v-icon> </v-list-item-icon
                    ><v-list-item-content>
                      <v-list-item-title>Editar</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                -->
                  <v-list-item
                    v-if="item.estado_id == 1"
                    @click="$deleteItem(item.id, item.razon_social)"
                  >
                    <v-list-item-icon class="mr-0">
                      <v-icon
                        small
                        class="mr-2"
                        :color="$cv('btnEliminar')"
                        title="Eliminar"
                      >
                        mdi-delete
                      </v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>Eliminar</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-col>
          </v-row>
        </template>
      </v-data-table>
    </v-card>

    <v-snackbar
      v-model="snackbar"
      :bottom="true"
      :color="color"
      :timeout="timeout"
    >
      <div v-html="text"></div>

      <template v-slot:action="{ attrs }">
        <v-btn dark text v-bind="attrs" @click="snackbar = false">
          Cerrar
        </v-btn>
      </template>
    </v-snackbar>
    <vue-confirm-dialog></vue-confirm-dialog>
  </v-container>
</template>

<script>
import Swal from "sweetalert2";
function title() {
  return "Solicitudes de Nuevos Concesionarios";
}

export default {
  data: (vm) => ({
    valid: true,
    nowDate: new Date().toISOString().slice(0, 10),
    _method: "PUT",
    autoGrow: true,
    rows: 1,
    title: title(),
    dessertActivo: "",
    route: "solicitudUsuario",
    routeApproveUser: "solicitudUsuarioApprove",
    routeChangeStatus: "solicitudUsuarioChangeStatus",
    routeBussinesPartners: "bussinessPartnerSL",
    titlePerms: title().toLowerCase(),

    menu: false,
    modal: false,
    menu2: false,
    dialog: false,
    showDialog: false,
    snackbar: false,
    visible: true,
    text: "Registro Insertado",
    color: "success",
    timeout: 4000,
    rules: [(v) => v.length <= 500 || "Max 500 caracteres"],
    search: "",
    vista: false,
    headers: [
      { text: "Acciones", value: "actions", sortable: false },
      { text: "Nro Solicitud", value: "id" },
      { text: "Estado", value: "estado_id" },
      { text: "CUIT", value: "cuit" },
      { text: "Razon Social", value: "razon_social" },
      { text: "Correo Concesionario", value: "datos_entidad" },
      //{ text: "Entidad", value: "datos_entidad" },
      //{ text: "Documento", value: "documento" },
      { text: "Nombre del Usuario", value: "datos_usuario" },

      { text: "Creado el", value: "created_at" },
    ],
    desserts: [],
    editedIndex: -1,
    editedItem: {
      datos_entidad: {},
      datos_usuario: {},
    },
    defaultItem: {},
    filters: {
      cuit: "",
      razon_social: "",
      estado_id: "",
    },
    filterKey: [],
    selectedHeaders: [],
    itemsSN: [
      { id: true, detalle: "SI" },
      { id: false, detalle: "NO" },
    ],
    estados: [
      { id: 1, detalle: "PENDIENTE" },
      { id: 2, detalle: "APROBADA" },
      { id: 3, detalle: "RECHAZADA" },
    ],
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1
        ? "Registrar " + this.title
        : this.vista == false
        ? "Editar " + this.title
        : this.title;
    },
    filteredData() {
      return this.$filteredData().data !== undefined
        ? this.$filteredData().data
        : this.$filteredData();
    },
    showHeaders() {
      return this.headers.filter((s) => this.selectedHeaders.includes(s));
    },
  },

  watch: {
    async dialog(val) {
      if (!val) {
        this.$close();
      }
    },
  },

  created() {
    this.$initialize();
    this.selectedHeaders = this.headers;
  },

  methods: {
    async editItem(id) {
      let vm = this;
      //aca toque
      Swal.alertGetInfo("Obteniendo información <br><b></b>");

      await vm.$axiosApi
        .getById(vm.route, id)
        .then((r) => {
          this.editedItem = r.data.data;
          this.editedIndex = id;
          this.showDialog = true;
          this.dialog = true;
          //console.log(r.data.data);
          Swal.close();
          //vm.snackbar = false;
        })
        .catch(function (error) {
          Swal.close();
          Vue.prototype.$respuesta(vm, error, 3, true);
        });
    },
    getDetalle(id, seccion) {
      let options = Array();
      switch (seccion) {
        case "estado":
          options = this.estados;
          break;
      }
      console.log(options);
      if (id) {
        return options.find((o) => o.id == id).detalle;
      }
    },
    /*async getLocationExternalCode(code) {
      try {
        if (code) {
          let locationCodeMatch = "locationCodeMatch";
          let params = code;
          const response = await this.$axiosApi.getByCriteria(
            locationCodeMatch,
            params
          );
          return response.data.data[0]
            ? response.data.data[0].external_code
            : null;
          //return response.data.data.external_code;
        }
      } catch (error) {
        Swal.close();
        console.log(error);
        
        return error.response.status;
      }
    },
    */
    async postClient(form) {
      try {
        let dataEntidad = JSON.parse(form.datos_entidad);
        let dataUsuario = JSON.parse(form.datos_usuario);
        let resp = await this.$isClient(form.cuit);
        console.log("buscando locationExternalCode");
        let locationExternalCode = await this.$getLocationExternalCode(
          dataEntidad.provincia.id
        );
        console.log("locationExternalCode");
        console.log(locationExternalCode);

        if (resp != 1) {
          let formSap = {};
          locationExternalCode = locationExternalCode
            ? locationExternalCode
            : "99";
          formSap.CardName = form.razon_social;
          formSap.FederalTaxID = form.cuit;
          formSap.U_B1SYS_FiscIdType = "80";
          formSap.Cellular = dataEntidad.celular;
          formSap.EmailAddress = dataEntidad.mail;
          formSap.Phone1 = dataEntidad.telefono;
          formSap.U_UnamePortal = dataUsuario.usuario;
          formSap.U_NamePortal =
            dataUsuario.apellido + ", " + dataUsuario.nombre;
          formSap.BPAddresses = [
            {
              AddressName: "Entrega",
              Country: "AR",
              State: locationExternalCode,
              City: dataEntidad.provincia.nombre,
              Street: dataEntidad.calle,
              StreetNo: dataEntidad.numero,
              ZipCode: dataEntidad.cp,
              AddressType: "bo_ShipTo",
            },
            {
              AddressName: "Fiscal",
              Country: "AR",
              State: locationExternalCode,
              City: dataEntidad.provincia.nombre,
              Street: dataEntidad.calle,
              StreetNo: dataEntidad.numero,
              ZipCode: dataEntidad.cp,
              AddressType: "bo_BillTo",
            },
          ];
          formSap.CardCode = dataUsuario.usuario;
          formSap.GroupCode = "104";
          formSap.Properties1 = "Y";
          formSap.Properties39 = "Y";
          formSap.Properties53 = "Y";
          formSap.CardType = "cLid";
          formSap.U_CodMarca = "3";

          const response = await this.$axiosApi.post(
            "bussinessPartnerSL",
            formSap
          );
          return response.data.code;
        } else {
          //el cuit ya esta en uso
          return -1;
        }
      } catch (error) {
        Swal.close();
        /*console.log(error.code);
          console.log(error.message);
          console.log(error.response.data);*/
        //console.error("Error al hacer la solicitud POST:", error);
        return error.response.status;
      }
    },

    async aprobarSolicitud(item, title, estado) {
      this.showDialog = this.dialog == true ? false : "";
      let solicitud = item.cuit + "/" + item.razon_social;
      let solicitante =
        JSON.parse(item.datos_usuario).apellido +
        ", " +
        JSON.parse(item.datos_usuario).nombre;
      let id = item.id;
      let textSwalOk = estado === 2 ? "aceptada" : "rechazada";

      let motivoRequerido = estado !== 2;
      let motivoMinLength = motivoRequerido ? 10 : 0;
      let msjPlaceholder = motivoRequerido
        ? "Observacion (mayor a 10 caracteres)"
        : "";

      Swal.fire({
        title:
          title +
          " solicitud de usuario de concesionario de " +
          item.razon_social +
          "(" +
          item.cuit +
          ")?",
        text: "Ingrese un comentario",
        html: "Datos del Usuario: <b>" + solicitante + "</b><br>",
        icon: "question",
        input: !this.$store.state.user.agencia_id ? "textarea" : "",
        inputPlaceholder: msjPlaceholder,
        inputAttributes: {
          autocapitalize: "on",
          "aria-label": "Ingrese una observacion...",
          minlength: motivoMinLength,
          maxlength: 1000,
          required: motivoRequerido,
        },
        showCancelButton: true,
        inputValidator: (value) => {
          return new Promise((resolve) => {
            if ((motivoRequerido && !value) || value.length < motivoMinLength) {
              resolve(
                "Debe ingresar un comentario (mínimo " +
                  motivoMinLength +
                  " caracteres)"
              );
            } else {
              resolve();
            }
          });
        },
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Aceptar",
        cancelButtonText: "Cancelar",
        showLoaderOnConfirm: true,
        allowOutsideClick: () => !Swal.isLoading(),
      }).then(async (result) => {
        if (result.isConfirmed) {
          let form = {};
          let dataEntidad = JSON.parse(item.datos_entidad);
          let dataUsuario = JSON.parse(item.datos_usuario);

          console.log(
            "buscando locationExternalCode " + dataEntidad.provincia.id
          );

          // Uso de await en el callback asíncrono
          let locationExternalCode = await this.$getLocationExternalCode(
            dataEntidad.provincia.id
          );
          console.log("locationExternalCode", locationExternalCode);
          locationExternalCode = locationExternalCode || "99";

          // Datos para el formulario
          form.id = id;
          form.comentario = result.value;
          form.estado = estado;
          form.lastName = dataUsuario.apellido;
          form.firstName = dataUsuario.nombre;
          form.CardName = item.razon_social;
          form.FederalTaxID = item.cuit;
          form.U_B1SYS_FiscIdType = "80";
          form.Cellular = dataEntidad.celular;
          form.EmailAddress = dataEntidad.mail;
          form.Phone1 = dataEntidad.telefono;
          form.U_UnamePortal = dataUsuario.usuario;
          form.U_NamePortal = dataUsuario.apellido + ", " + dataUsuario.nombre;
          form.BPAddresses = [
            {
              AddressName: "Entrega",
              Country: "AR",
              State: locationExternalCode,
              City: dataEntidad.provincia.nombre,
              Street: dataEntidad.calle,
              StreetNo: dataEntidad.numero,
              ZipCode: dataEntidad.cp,
              AddressType: "bo_ShipTo",
            },
            {
              AddressName: "Fiscal",
              Country: "AR",
              State: locationExternalCode,
              City: dataEntidad.provincia.nombre,
              Street: dataEntidad.calle,
              StreetNo: dataEntidad.numero,
              ZipCode: dataEntidad.cp,
              AddressType: "bo_BillTo",
            },
          ];
          form.CardCode = dataUsuario.usuario;
          form.GroupCode = "104";
          form.Properties1 = "Y";
          form.Properties39 = "Y";
          form.Properties53 = "Y";
          form.CardType = "C"; //cLid
          form.U_CodMarca = "3";

          Swal.fire({
            title: "Aguarde un instante!",
            icon: "info",
            html: "Procesando la Solicitud " + solicitud,
            allowOutsideClick: false,
            showConfirmButton: false,
            onBeforeOpen: () => {
              Swal.showLoading();
            },
          });

          try {
            const response = await this.$axiosApi.post(
              this.routeApproveUser,
              form
            );
            console.log(response);
            if (response.status === 200) {
              Swal.close();
              Swal.fire({
                icon: "success",
                title: "Operación Exitosa",
                text: "El usuario fue creado en SAP. La gestión del nuevo usuario deberá hacerla desde SAP",
              });
              this.$initialize();
            } else {
              throw new Error("Error en la solicitud");
            }
          } catch (error) {
            Swal.close();
            const message = error.response?.data?.message || error.message;
            Swal.fire({
              icon: "error",
              title: "Error",
              text: "No se pudo aprobar la solicitud. " + message,
              footer: "Contacte con el administrador",
            });
          }
        } else {
          this.showDialog = this.dialog === true;
          console.log("cancelar");
        }
      });
    },
    /*async aprobarSolicitud(item, title, estado) {
      try {
        let form = {};
        let solicitud = item.cuit + " - " + item.razon_social;
        let id = item.id;
        let dataEntidad = JSON.parse(item.datos_entidad);
        let dataUsuario = JSON.parse(item.datos_usuario);
        // datos para el changestatus
        form.id = id;
        form.comentario = "";
        form.estado = estado;
        // datos para el  sap
        form.CardName = item.razon_social;
        form.FederalTaxID = item.cuit;
        form.U_B1SYS_FiscIdType = "80";
        form.Cellular = dataEntidad.celular;
        form.EmailAddress = dataEntidad.mail;
        form.Phone1 = dataEntidad.telefono;
        form.U_UnamePortal = dataUsuario.usuario;
        form.U_NamePortal = dataUsuario.apellido + ", " + dataUsuario.nombre;
        form.BPAddresses = [
          {
            AddressName: "Entrega",
            Country: "AR",
            State: "00",
            City: dataEntidad.provincia.nombre,
            Street: dataEntidad.calle,
            StreetNo: dataEntidad.numero,
            ZipCode: dataEntidad.cp,
            AddressType: "bo_ShipTo",
          },
          {
            AddressName: "Fiscal",
            Country: "AR",
            State: "00",
            City: dataEntidad.provincia.nombre,
            Street: dataEntidad.calle,
            StreetNo: dataEntidad.numero,
            ZipCode: dataEntidad.cp,
            AddressType: "bo_BillTo",
          },
        ];
        form.CardCode = dataUsuario.usuario;
        form.GroupCode = "104";
        form.Properties1 = "Y";
        form.Properties39 = "Y";
        form.Properties53 = "Y";
        form.CardType = "L";
        form.U_CodMarca = "3";

        Swal.fire({
          title: "Aguarde un instante!",
          icon: "info",
          html: "Procesando la Solicitud " + solicitud,
          allowOutsideClick: false,
          showConfirmButton: false,
          onBeforeOpen: () => {
            Swal.showLoading();
          },
        });
        this.$axiosApi.post(this.routeApproveUser, form).then(
          (response) => {
            console.log(response);
            if (response.status == 200) {
              Swal.close();
              Swal.fire({
                icon: "success",
                title: "Operacion Exitosa",
                text: "El usuario fue creado en SAP. La gestión del nuevo usuario deberá hacerla desde SAP",
              });
              this.$initialize();
              //this.getSolicitudes();
            } else {
              Swal.close();
              let message = response;
              Swal.fire({
                icon: "error",
                title: "Error",
                text: "No se pudo aprobar la solicitud. " + message,
                footer: "Contacte con el administrador",
              });
            }
          },
          (error) => {
            Swal.close();
            let message =
              typeof error.response.data.message !== "undefined"
                ? error.response.data.message
                : error;
            Swal.fire({
              icon: "error",
              title: "Error",
              text: "No se pudo aprobar la solicitud. " + message,
              footer: "Contacte con el administrador",
            });
          }
        );
      } catch (error) {
        console.log(error);
        Swal.close();
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "No se pudo aprobar la solicitud",
          footer: "Contacte con el administrador",
        });
      }
    },*/
    /*async aprobarSolicitud(item, title, estado) {
      try {
        let solicitud = item.cuit + "/" + item.razon_social;

        let id = item.id;
        Swal.fire({
          title: "Aguarde un instante!",
          icon: "info",
          html: "Procesando la Solicitud " + solicitud,
          allowOutsideClick: false,
          showConfirmButton: false,
          onBeforeOpen: () => {
            Swal.showLoading();
          },
        });
        let formData = {
          id: id,
          comentario: "",
          estado: estado,
        };

        let resp = await this.postClient(item);

        if (resp == 201) {
          this.$axiosApi.post(this.routeChangeStatus, formData).then(
            (response) => {
              console.log(response);
              if (response.status == 200) {
                Swal.close();
                Swal.fire({
                  icon: "success",
                  title: "Operacion Exitosa",
                  text: "El usuario fue creado en SAP. La gestión del nuevo usuario deberá hacerla desde SAP",
                });
                this.$initialize();
                //this.getSolicitudes();
              } else {
                Swal.close();
                Swal.fire({
                  icon: "warning",
                  title: "La Solicitud  N°" + solicitud + " está incompleta",
                  html: "<div align='left'>" + response.data.msj + "</div>",
                });
              }
            },
            (error) => {
              Swal.close();
              Swal.fire({
                icon: "error",
                title: "Error",
                text: "No se pudo aprobar la solicitud" + error,
                footer: "Contacte con el administrador",
              });
            }
          );
        } else if (resp == -1) {
          Swal.close();
          Swal.fire({
            icon: "error",
            title: "Error",
            text: "Ya existe ese usuario en SAP",
            footer: "Contacte con el administrador",
          });
        } else {
          Swal.close();
          Swal.fire({
            icon: "error",
            title: "Error",
            text: "No se pudo aprobar la solicitud",
            footer: "Contacte con el administrador",
          });
        }
      } catch (error) {
        console.log(error);
        Swal.close();
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "No se pudo aprobar la solicitud",
          footer: "Contacte con el administrador",
        });
      }
    },*/

    async changeStatus(item, title, estado) {
      //si se aprueba la solicitud, tengo q llamar al endpoint para registrar en SAP

      this.showDialog = this.dialog == true ? false : "";
      let solicitud = item.cuit + "/" + item.razon_social;
      let solicitante =
        JSON.parse(item.datos_usuario).apellido +
        ", " +
        JSON.parse(item.datos_usuario).nombre;
      let id = item.id;
      let textSwalOk = "";
      switch (estado) {
        case 2:
          textSwalOk = "aceptada";
          break;
        case 3:
          textSwalOk = "rechazada";

          break;
      }
      //let financia = parseFloat(item.monto.replace(",", "."));
      let motivoRequerido = true;
      let motivoMinLength = 10;
      let msjPlaceholder =
        "Observacion (mayor a " + motivoMinLength + " caracteres)";
      if (estado == 2) {
        motivoRequerido = false;
        motivoMinLength = 0;
        msjPlaceholder = "";
      }
      Swal.fire({
        title:
          title +
          " solicitud de usuario de concesionario de " +
          item.razon_social +
          "(" +
          item.cuit +
          ")?",
        text: "Ingrese un comentario ",
        html: "Datos del Usuario: <b>" + solicitante + "</b><br>",
        icon: "question",
        input: !this.$store.state.user.agencia_id ? "textarea" : "",
        inputPlaceholder: msjPlaceholder,
        inputAttributes: {
          autocapitalize: "on",
          "aria-label": "Ingrese una observacion...",
          minlength: motivoMinLength,
          maxlength: 1000,
          required: motivoRequerido,
        },
        showCancelButton: true,
        inputValidator: (value) => {
          return new Promise((resolve) => {
            if ((motivoRequerido && !value) || value.length < motivoMinLength) {
              resolve(
                "Debe ingresar un comentario (minimo " +
                  motivoMinLength +
                  " caracteres)"
              );
            } else {
              resolve();
            }
          });
        },
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Aceptar",
        cancelButtonText: "Cancelar",
        showLoaderOnConfirm: true,
        allowOutsideClick: () => !Swal.isLoading(),
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.fire({
            title: "Aguarde un instante!",
            icon: "info",
            html: "Procesando la Solicitud " + solicitud,
            allowOutsideClick: false,
            showConfirmButton: false,
            onBeforeOpen: () => {
              Swal.showLoading();
            },
          });
          let formData = {
            id: id,
            comentario: result.value,
            estado: estado,
          };

          this.$axiosApi.post(this.routeChangeStatus, formData).then(
            (response) => {
              console.log(response);
              if (response.status == 200) {
                Swal.close();
                Swal.fire({
                  icon: "success",
                  title: "Operacion Exitosa",
                  text: "La solicitud ha sido " + textSwalOk,
                });
                this.$initialize();
                //this.getSolicitudes();
              } else {
                Swal.close();
                Swal.fire({
                  icon: "warning",
                  title: "La Solicitud  N°" + solicitud + " está incompleta",
                  html: "<div align='left'>" + response.data.msj + "</div>",
                });
              }
            },
            (error) => {
              Swal.close();
              Swal.fire({
                icon: "error",
                title: "Error",
                text: "No se pudo registrar la solicitud" + error,
                footer: '<a href="">Contacte con el administrador</a>',
              });
            }
          );
        } else {
          this.showDialog = this.dialog == true ? true : false;
          console.log("cancelar");
        }
      });
    },
    filterByCuit(item) {
      return this.$filterBy(item, "cuit");
    },
    filterByRazon_social(item) {
      return this.$filterBy(item, "razon_social");
    },
    filterByEstado_id(item) {
      return this.$filterBy(item, "estado_id");
    },
  },
  mounted() {
    //this.$hideMenu();
    //this.$getData(["tipo_tramites", "tipo_certificados", "tipo_vehiculos"]);
    console.log("Componente " + this.route + " creado");
  },
};
</script>
<style scoped>
.text-fields-row {
  display: flex;
}
.x-btn-grid {
  flex: unset;
  background-color: bisque;
  margin: 0 0px;
}

/* Ajustar z-index de SweetAlert2 */
.swal2-container {
  z-index: 999999 !important;
}
.swal2-content {
  z-index: 999999 !important;
}
</style>
